<template>
  <div>
    <loader v-if="isLoading" message="Loading ...." />
    <app-form
      v-else
      :value="value"
      :showModal="showModal"
      :showCreateProjectModal="showCreateProjectModal"
      @show="showAddEndpointModal"
      @project="startCreateProjectModal"
      @submitProject="createProject"
      @submit="editApp"
    />
  </div>
</template>

<script>
import AppForm from '@/components/apps/AppForm'
import Loader from '@/components/cards/Loader'
import { mapGetters } from 'vuex'
export default {
  name: 'EditApp',
  components: {
    'app-form': AppForm,
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      app: 'app/currentApp',
    }),
  },
  data() {
    return {
      showModal: this.$route.meta.showModal,
      showCreateProjectModal: this.$route.meta.showCreateProjectModal,
      isLoading: false,
      value: {
        name: '',
        description: '',
        project: null,
        envars: [],
      },
    }
  },
  created() {
    this.$store.dispatch('app/getProjects')
  },
  methods: {
    showAddEndpointModal() {
      this.$router.push({ name: 'addMoreEndpoint' })
    },
    startCreateProjectModal() {
      this.$router.push({ name: 'addAppProject' })
    },
    async getAppDetails() {
      this.isLoading = true
      await this.$store.dispatch('app/getAppDetails', this.id)
      this.isLoading = false
    },
    editApp() {
      this.isSubmitting = true
      this.$store
        .dispatch('app/editApp', { payload: this.value, id: this.id })
        .then(() => {
          this.$router.push({ name: 'appDetails', params: { id: this.id } })
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    createProject(payload) {
      this.$store.dispatch('app/createProject', payload).then(() => {
        this.$router.go(-1)
      })
    },
  },
  watch: {
    watch: {
      '$route.meta'({ showModal, showCreateProjectModal }) {
        this.showModal = showModal
        this.showCreateProjectModal = showCreateProjectModal
      },
    },
    app: {
      handler() {
        if (this.app.id) {
          this.value = Object.assign({}, this.app)
          return
        }
        this.getAppDetails()
      },
      immediate: true,
    },
  },
}
</script>
<style>
.img {
  height: 300px;
}
.bg-white {
  background: white;
}
</style>
